import { UserRole } from "@jugl-web/rest-api";
import { useTranslations } from "@jugl-web/utils";
import { useMemo } from "react";

export const useUserRoleName = () => {
  const { t } = useTranslations();

  const roleNameMap = useMemo(
    () => ({
      [UserRole.admin]: t({
        id: "cpanel-page.admin",
        defaultMessage: "Admin",
      }),
      [UserRole.employee]: t({
        id: "cpanel-page.employee",
        defaultMessage: "Employee",
      }),
      [UserRole.member]: t({
        id: "cpanel-page.member",
        defaultMessage: "Member",
      }),
    }),
    [t]
  );

  return {
    roleNameMap,
  };
};
