import { RtkBaseQuery, RtkEmptySplitApi } from "../../types";
import { fetchAllPages } from "../../utils";
import {
  EntityInvitationModel,
  EntityModel,
  InvoiceModel,
  SubscriptionInfoModel,
} from "./models";
import { EntityDto } from "./models/Entity/EntityDto";
import { transformEntityDtoToModel } from "./models/Entity/transformers";
import { EntityInvitationDto } from "./models/EntityInvitation/EntityInvitationDto";
import { transformEntityInvitationDtoToModel } from "./models/EntityInvitation/transformers";
import { SubscriptionPlanModel } from "./models/SubscriptionPlan/SubscriptionPlanModel";
import { SubscriptionPlanDto } from "./models/SubscriptionPlan/SubscriptionPlanDto";
import { transformSubscriptionPlansDtoToModels } from "./models/SubscriptionPlan/transformers";
import { RespondToEntityInviteMutationPayload } from "./payloads";
import { EntitiesApiTag } from "./tags";
import {
  EntityUpdateIndicator,
  ModuleUnreadIndicators,
  UnreadIndicatorSupportedModule,
  InvitationLinkResponse,
} from "./types";
import { SubscriptionCustomerAddress } from "./models/common-types/SubscriptionCustomerAddress";
import { PromoCodeInfo } from "./models/common-types/PromoCodeInfo";
import { transformSubscriptionInfoDtoToModel } from "./models/SubscriptionInfo/transformers";
import { SubscriptionInfoDto } from "./models/SubscriptionInfo/SubscriptionInfoDto";

export const addEntitiesApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      EntitiesApiTag.getEntity,
      EntitiesApiTag.getEntityList,
      EntitiesApiTag.getSubscriptionInfo,
    ],
  });

  const entitiesApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      createEntity: builder.mutation<
        EntityModel,
        {
          data: Partial<Pick<EntityDto, "name" | "country" | "billing_email">>;
          displayImg?: File;
        }
      >({
        query: ({ data, displayImg }) => {
          const binData = new FormData();

          binData.append(
            "entity",
            JSON.stringify({
              ...data,
              type: "org",
            })
          );
          if (displayImg) {
            binData.append("display_pic_file", displayImg);
          }

          return {
            url: `/api/auth/entity`,
            method: "POST",
            data: binData,
            silentError: true,
          };
        },
        onQueryStarted: (e0, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then((result) => {
              dispatch(
                entitiesApi.util.updateQueryData(
                  "_DEPRECATED_getAllOrgEntitiesList",
                  undefined,
                  (entities) => {
                    entities.unshift(result.data);
                  }
                )
              );
            })
            .catch(() => {});
        },
        invalidatesTags: (result, error) =>
          error ? [] : [EntitiesApiTag.getEntityList],
        transformResponse: (response: EntityDto) =>
          transformEntityDtoToModel(response),
      }),

      editEntity: builder.mutation<
        void,
        {
          entityId: string;
          data: Partial<Pick<EntityDto, "name" | "country" | "billing_email">>;
          displayImg?: File;
        }
      >({
        query: ({ data, entityId, displayImg }) => {
          const binData = new FormData();

          binData.append("entity", JSON.stringify(data));
          if (displayImg) {
            binData.append("display_pic_file", displayImg);
          }

          return {
            url: `/api/auth/entity/${entityId}`,
            method: "PUT",
            data: binData,
          };
        },
        onQueryStarted: ({ data, entityId }, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            entitiesApi.util.updateQueryData(
              "_DEPRECATED_getAllOrgEntitiesList",
              undefined,
              (entities) =>
                entities.map((entity) =>
                  entity.id === entityId ? { ...entity, ...data } : entity
                )
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: (e0, e1, { entityId }) => [
          EntitiesApiTag.getEntityList,
          { type: EntitiesApiTag.getEntity, id: entityId },
        ],
      }),

      deleteEntity: builder.mutation<void, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}`,
          method: "DELETE",
        }),
        onQueryStarted: ({ entityId }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() => {
            dispatch(
              entitiesApi.util.updateQueryData(
                "_DEPRECATED_getAllOrgEntitiesList",
                undefined,
                (entities) => entities.filter((item) => item.id !== entityId)
              )
            );
          });
        },
      }),

      getEntity: builder.query<EntityModel, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}`,
        }),
        providesTags: (e0, e1, { entityId }) => [
          { type: EntitiesApiTag.getEntity, id: entityId },
        ],
        transformResponse: (dto: EntityDto) => transformEntityDtoToModel(dto),
      }),

      _DEPRECATED_getAllOrgEntitiesList: builder.query<EntityModel[], void>({
        queryFn: async (params, e1, e2, baseQuery) => {
          try {
            const allEntitiesDto = await fetchAllPages<EntityDto>(
              "/api/auth/entity/list",
              { type: "org" },
              baseQuery as unknown as RtkBaseQuery
            );
            return {
              data: allEntitiesDto.map((entity) =>
                transformEntityDtoToModel(entity)
              ),
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: [EntitiesApiTag.getEntityList],
      }),

      getModuleUnreadIndicators: builder.query<
        ModuleUnreadIndicators,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
        }),
        transformResponse: (response: { data: EntityUpdateIndicator[] }) => {
          const state: ModuleUnreadIndicators = {
            task: 0,
            people: 0,
            drive: 0,
            chat: 0,
            call: 0,
            customer: 0,
          };

          response.data.forEach((indicator) => {
            state[indicator.module] = Number(indicator.entity_unread);
          });

          return state;
        },
      }),

      markEntityAsUnread: builder.mutation<null, { entityId: string }>({
        queryFn: () => ({ data: null }),
        onQueryStarted: ({ entityId }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() => {
            dispatch(
              entitiesApi.util.updateQueryData(
                "getEntity",
                { entityId },
                (entity) => {
                  entity.unread = true;
                }
              )
            );
            dispatch(
              entitiesApi.util.updateQueryData(
                "_DEPRECATED_getAllOrgEntitiesList",
                undefined,
                (entities) => {
                  const foundEntity = entities.find(
                    (entity) => entity.id === entityId
                  );

                  if (foundEntity) {
                    foundEntity.unread = true;
                  }
                }
              )
            );
          });
        },
      }),

      markEntityAsRead: builder.mutation<unknown, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
          method: "POST",
          data: {
            type: "user_unread",
          },
          silentError: true,
        }),
        onQueryStarted: ({ entityId }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() => {
            dispatch(
              entitiesApi.util.updateQueryData(
                "getEntity",
                { entityId },
                (entity) => {
                  entity.unread = false;
                }
              )
            );
            dispatch(
              entitiesApi.util.updateQueryData(
                "_DEPRECATED_getAllOrgEntitiesList",
                undefined,
                (entities) => {
                  const foundEntity = entities.find(
                    (entity) => entity.id === entityId
                  );

                  if (foundEntity) {
                    foundEntity.unread = false;
                  }
                }
              )
            );
          });
        },
      }),

      markEntityModuleAsRead: builder.mutation<
        unknown,
        { entityId: string; module: UnreadIndicatorSupportedModule }
      >({
        query: ({ entityId, module }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
          method: "POST",
          data: {
            type: "entity_unread",
            module,
          },
          silentError: true,
        }),
      }),

      updateModuleUnreadIndicators: builder.mutation<
        null,
        { entityId: string; state: ModuleUnreadIndicators }
      >({
        queryFn: () => ({ data: null }),
        onQueryStarted: ({ entityId, state }, { dispatch }) => {
          dispatch(
            entitiesApi.util.updateQueryData(
              "getModuleUnreadIndicators",
              { entityId },
              () => state
            )
          );
        },
      }),

      getAllEntitiesModuleUpdateIndicators: builder.query<
        { data: Record<string, EntityUpdateIndicator[]> },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/entity/${entityId}/visits`,
          params: { type: "all" },
        }),
        transformResponse: (
          response: {
            data: Record<string, EntityUpdateIndicator[]>;
          },
          _,
          { entityId }
        ) => {
          // Filter out current entity as backend includes it in the response for some reason
          if (entityId in response.data) {
            delete response.data[entityId];
          }

          return response;
        },
      }),

      getLatestInvoice: builder.query<InvoiceModel, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/latest_invoice`,
        }),
      }),

      getUpcomingInvoice: builder.query<InvoiceModel, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/upcoming_invoice`,
        }),
      }),

      getInvoicesList: builder.query<
        { invoice_list: InvoiceModel[]; remaining_item: boolean },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/list_invoices`,
        }),
      }),

      getSubscriptionInfo: builder.query<
        SubscriptionInfoModel,
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/subscription`,
        }),
        transformResponse: (dto: SubscriptionInfoDto) =>
          transformSubscriptionInfoDtoToModel(dto),
        providesTags: (result, error, { entityId }) => [
          { type: EntitiesApiTag.getSubscriptionInfo, id: entityId },
        ],
      }),

      getSubscriptionPlans: builder.query<
        SubscriptionPlanModel[],
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/plans`,
        }),
        transformResponse: (data: { plan_list: SubscriptionPlanDto[] }) =>
          transformSubscriptionPlansDtoToModels(data.plan_list),
      }),

      updateBillingInfo: builder.mutation<
        { result: "success" },
        {
          entityId: string;
          data: {
            address: SubscriptionCustomerAddress;
            name: string;
            notes?: string;
          };
        }
      >({
        query: ({ entityId, data }) => ({
          method: "PUT",
          url: `/api/auth/${entityId}/payments/v1/billing_info`,
          data,
        }),
        onQueryStarted: ({ entityId, data }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() => {
            dispatch(
              entitiesApi.util.updateQueryData(
                "getSubscriptionInfo",
                { entityId },
                (currentData) => {
                  currentData.customerAddress = data.address;
                  currentData.customerName = data.name;
                  return currentData;
                }
              )
            );
          });
        },
      }),

      upgradeSubscription: builder.mutation<
        | { result: "success" }
        | { result: "action_required"; secret: string; pub_key: string },
        {
          entityId: string;
          data: {
            price_id: string;
            additional_seats?: { id: string; qty: number };
            promo_code?: string | string[];
          };
        }
      >({
        query: ({ entityId, data }) => ({
          method: "POST",
          url: `/api/auth/${entityId}/payments/v1/change_plan`,
          data,
        }),
      }),

      updateSeats: builder.mutation<
        | { result: "success" }
        | { result: "action_required"; secret: string; pub_key: string },
        { entityId: string; data: { qty: number; price_id: string } }
      >({
        query: ({ entityId, data }) => ({
          method: "POST",
          url: `/api/auth/${entityId}/payments/v1/update_seats`,
          data,
        }),
        invalidatesTags: (result, error, { entityId }) =>
          result
            ? [{ type: EntitiesApiTag.getSubscriptionInfo, id: entityId }]
            : [],
      }),

      updateCardDetails: builder.mutation<
        { secret: string; pub_key: string; type: string },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          method: "PUT",
          url: `/api/auth/${entityId}/payments/v1/card`,
        }),
      }),

      cancelSubscription: builder.mutation<
        { secret: string; pub_key: string },
        { entityId: string; data: { entity_name: string; reason: string } }
      >({
        query: ({ entityId, data }) => ({
          method: "DELETE",
          url: `/api/auth/${entityId}/payments/v1/subscription`,
          data,
        }),
      }),

      verifyEmail: builder.mutation<
        void,
        { entityId: string; data: { billing_email: string; otp?: string } }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/entity/${entityId}/verify_email`,
          method: "POST",
          data,
        }),
        onQueryStarted: (
          { data: { billing_email, otp }, entityId },
          { dispatch, queryFulfilled }
        ) => {
          const patchResult = dispatch(
            entitiesApi.util.updateQueryData(
              "_DEPRECATED_getAllOrgEntitiesList",
              undefined,
              (entities) =>
                entities.map((entity) =>
                  otp && entity.id === entityId
                    ? {
                        ...entity,
                        billingEmail: billing_email,
                        isEmailVerified: true,
                      }
                    : entity
                )
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),

      getEntityInvites: builder.query<EntityInvitationModel[], void>({
        query: () => ({ url: "/api/auth/invites" }),
        transformResponse: (data: EntityInvitationDto[]) =>
          data.map(transformEntityInvitationDtoToModel),
      }),

      leaveEntity: builder.mutation<
        string,
        { entityId: string; entityRelId: string }
      >({
        query: ({ entityId, entityRelId }) => ({
          url: `/api/auth/entity/${entityId}/users/${entityRelId}/leave`,
          method: "DELETE",
          silentError: true,
        }),
        invalidatesTags: () => [EntitiesApiTag.getEntityList],
      }),

      acceptEntityInvitationByLink: builder.mutation<
        { result: "success" },
        {
          entityId: string;
          inviteId: string;
        }
      >({
        query: ({ entityId, inviteId }) => ({
          url: `/api/auth/invite_link/${entityId}/link/${inviteId}`,
          method: "POST",
        }),
        invalidatesTags: [EntitiesApiTag.getEntityList],
      }),

      respondToEntityInvite: builder.mutation<
        void,
        RespondToEntityInviteMutationPayload
      >({
        query: ({ inviteId, action }) => ({
          url: `/api/auth/invites/${inviteId}?action=${action}`,
          method: "PUT",
        }),
        onQueryStarted: ({ inviteId }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(() => {
            dispatch(
              entitiesApi.util.updateQueryData(
                "getEntityInvites",
                undefined,
                (entities) => entities.filter((item) => item.id !== inviteId)
              )
            );
          });
        },
        invalidatesTags: (result, error, { action }) =>
          !error && action === "accept" ? [EntitiesApiTag.getEntityList] : [],
      }),

      validatePromoCode: builder.mutation<
        PromoCodeInfo,
        { entityId: string; data: { code: string } }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/${entityId}/payments/v1/promo_code`,
          method: "POST",
          data,
        }),
      }),

      retryInvoice: builder.mutation<void, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/retry_invoice`,
          method: "PUT",
        }),
      }),

      _TEST_getCurrentAdvancedDate: builder.query<
        { current_time: string },
        { entityId: string }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/current_time`,
          silentError: true,
        }),
      }),
      _TEST_advanceDate: builder.mutation<
        void,
        { entityId: string; data: { day: number } }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/${entityId}/payments/v1/advance_days`,
          method: "POST",
          data,
          silentError: true,
        }),
      }),
      _TEST_removePayment: builder.mutation<void, { entityId: string }>({
        query: ({ entityId }) => ({
          url: `/api/auth/${entityId}/payments/v1/remove_payment`,
          method: "POST",
        }),
      }),
      createInvitationLink: builder.mutation<
        InvitationLinkResponse,
        {
          entityId: string;
        }
      >({
        query: ({ entityId }) => ({
          url: `/api/auth/invite_link/${entityId}`,
          method: "POST",
        }),
      }),
    }),
  });

  return entitiesApi;
};

export type EntitiesApi = ReturnType<typeof addEntitiesApi>;
