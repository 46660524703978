import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTasks } from "@jugl-web/domain-resources/tasks/hooks/useTasks";
import { useHeadlessUsersList } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { useSessionStorage } from "@jugl-web/utils/hooks/useStorage";
import { TASK_COLLAPSED_CALENDAR_REPORTEE_PANELS_BY_ENTITY_ID_KEY } from "@jugl-web/utils/storage";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { MoreTasksLoading } from "@web-src/features/tasks/MoreTasksLoading";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useWeekdays } from "../../hooks/useWeekdays";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { CalendarWeekColumnHeader } from "../CalendarWeekColumnHeader";
import { CalendarWeekReporteePanel } from "../CalendarWeekReporteePanel/CalendarWeekReporteePanel";

type CollapsedReporteePanelsByEntityId = Record<
  string,
  Record<string, boolean>
>;

export const CalendarTeamWeekView: FC = () => {
  const { taskListMode, customerContext } = useTasksPageContext();

  const { searchQuery, filters } = useTaskFiltering();
  const { sorting } = useTaskSorting();

  const meId = useSelector(selectUserId) || "";
  const { entityId } = useEntitySelectedProvider();

  const {
    users,
    isLoading: areUsersLoading,
    loadMore: loadMoreUsers,
    reachedEnd: usersEndReached,
  } = useHeadlessUsersList({
    entityId,
    sortBy: "name",
    onlyReportees: true,
  });

  const { selectTasksByReporteeId } = useTasks({
    entityId,
    mode: taskListMode,
    searchQuery,
    filters,
    sorting,
    showFuture: true,
    customerId: customerContext?.customerId,
  });

  const [
    collapsedReporteePanelsByEntityId,
    setCollapsedReporteePanelsByEntityId,
  ] = useSessionStorage<CollapsedReporteePanelsByEntityId>(
    TASK_COLLAPSED_CALENDAR_REPORTEE_PANELS_BY_ENTITY_ID_KEY,
    {}
  );

  const { ref: moreTasksLoadingRef, inView } = useInView();

  const weekdays = useWeekdays();

  const shouldShowMoreTasksLoading = !usersEndReached;

  const sortedUserIds = useMemo(() => {
    const userIds: string[] = [];

    const isManagerFilteredOut =
      filters.assignees.length > 0 &&
      !filters.assignees.includes(null) &&
      !filters.assignees.includes(meId);

    if (!isManagerFilteredOut) {
      userIds.push(meId);
    }

    users.forEach((user) => {
      const isUserMe = user.id === meId;

      if (isUserMe) {
        return;
      }

      const isUserFilteredOut =
        filters.assignees.length > 0 && !filters.assignees.includes(user.id);

      if (isUserFilteredOut) {
        return;
      }

      userIds.push(user.id);
    });

    return userIds;
  }, [filters.assignees, meId, users]);

  const handleToggleReporteePanel = (reporteeId: string) => {
    setCollapsedReporteePanelsByEntityId((previousState) => ({
      ...previousState,
      [entityId]: {
        ...previousState[entityId],
        [reporteeId]: !previousState[entityId]?.[reporteeId],
      },
    }));
  };

  const isReporteePanelCollapsed = (reporteeId: string) =>
    !!collapsedReporteePanelsByEntityId[entityId]?.[reporteeId];

  useEffect(() => {
    if (inView && !areUsersLoading && !usersEndReached) {
      loadMoreUsers();
    }
  }, [areUsersLoading, inView, loadMoreUsers, usersEndReached]);

  return (
    <div className="animate-fade-in flex w-full flex-col gap-1">
      <div className="flex w-full items-center gap-0.5">
        {weekdays.map((weekday) => (
          <CalendarViewLayout.Column
            key={weekday.date.valueOf()}
            className="group min-w-[207px]"
          >
            <CalendarWeekColumnHeader date={weekday.date} />
          </CalendarViewLayout.Column>
        ))}
      </div>
      <div className="flex max-h-full flex-col gap-[18px] overflow-y-auto pb-6">
        {sortedUserIds.map((userId) => (
          <CalendarWeekReporteePanel
            isCollapsed={isReporteePanelCollapsed(userId)}
            key={userId}
            userId={userId}
            meId={meId}
            tasks={selectTasksByReporteeId(userId)}
            onToggle={() => handleToggleReporteePanel(userId)}
          />
        ))}
        {shouldShowMoreTasksLoading && (
          <MoreTasksLoading
            ref={moreTasksLoadingRef}
            className="mx-8 mt-1.5 mb-6"
          />
        )}
      </div>
    </div>
  );
};
