import { useRestApiProvider } from "@jugl-web/rest-api";
import React, { useMemo, useState } from "react";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { ExpectedTaskCustomDropdownFieldValue } from "@jugl-web/rest-api/tasks";
import {
  getDueDateBasedOnDays,
  getDueDateBasedOnDaysAndTime,
  taskChecklistItemAdapters,
  templateChecklistItemAdapters,
} from "../../../tasks";
import { OrderFormCustomField } from "../../types";
import {
  OrderFormSubmission,
  OrderFormSubmissionValues,
} from "../OrderFormSubmission/OrderFormSubmission";
import linkExpiredAnimation from "./assets/error-animation.json";

export const OrderSubmitForm: React.FC<{
  entityId: string;
  formId: string;
  isGuestApp?: boolean;
  onSubmit?: () => void;
}> = ({ entityId, formId, isGuestApp, onSubmit }) => {
  const { ordersApi } = useRestApiProvider();

  const { isLoading, data, isError, isFetching } =
    ordersApi.useGuestOrderFormQuery(
      {
        entityId,
        formId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const {
    isLoading: isInitLoading,
    data: initData,
    isFetching: isInitFetching,
  } = ordersApi.useGuestInitQuery(
    { entityId },
    { refetchOnMountOrArgChange: true }
  );

  const [submitOrderForm, { isLoading: isSubmitting }] =
    ordersApi.useGuestSubmitOrderMutation();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const formFields = useMemo(() => {
    const result: OrderFormCustomField[] = [];
    data?.fields.forEach((item) => {
      if (item.id === TASK_ORDER_EMAIL_ID) {
        result.push({
          id: item.id,
          name: "Email",
          type: "text",
          order: -1,
          isShownInCard: false,
          isRequired: true,
        } as OrderFormCustomField);
        return;
      }
      const customField = initData?.custom_fields?.props?.value.find(
        (customFieldItem) => customFieldItem.id === item.id
      );
      if (!customField) {
        return;
      }
      result.push({
        id: customField.id,
        name: customField.field,
        type: customField.field_type,
        order: item.order,
        isShownInCard: false,
        values:
          customField.values as unknown as ExpectedTaskCustomDropdownFieldValue[],
        isRequired: item.required,
      });
    });
    return result;
  }, [data?.fields, initData?.custom_fields?.props?.value]);

  const $content = data ? (
    <OrderFormSubmission
      isGuestApp={isGuestApp}
      onSubmit={async (formValues) => {
        if (!entityId || !formValues) {
          return;
        }
        const customFields: Record<string, string> = {};
        Object.keys(formValues).forEach((key) => {
          if (formValues[key] instanceof Date) {
            customFields[key] = (formValues[key] as Date).toISOString();
            return;
          }
          customFields[key] = formValues[key] as string;
        });
        const resp = await submitOrderForm({
          entityId,
          formId,
          data: {
            name: data.name,
            priority: data.priority || null,
            due_at: (() => {
              if (data.due_in !== null) {
                return (
                  data.due_at
                    ? getDueDateBasedOnDaysAndTime(data.due_at, data.due_in)
                    : getDueDateBasedOnDays(data.due_in)
                ).toISOString();
              }
              return null;
            })(),
            fields: Object.keys(formValues).map((key) => ({
              id: key,
              value: formValues[key] as string,
            })),
            checklist: data.checklist
              .map(templateChecklistItemAdapters.toInternalModel)
              .map(taskChecklistItemAdapters.toBackendModel),
            custom_fields: customFields,
            // custom_fields: {},
            can_assignee_edit: data.can_assignee_edit,
            has_chklist_chk: data.has_chklist_chk,
          },
        });
        if (resp && "data" in resp) {
          if (isGuestApp) {
            setIsSubmitted(true);
          }
          onSubmit?.();
        }
      }}
      isSubmitted={isGuestApp ? isSubmitted : undefined}
      submittedMessage={data.msg}
      onReset={isGuestApp ? () => setIsSubmitted(false) : undefined}
      isLoading={isSubmitting}
      values={{
        title: data.title || "",
        description: data.form_desc || "",
        image: data.banner_img,
        fields: (formFields as OrderFormSubmissionValues["fields"]) || [],
      }}
    />
  ) : null;

  return (
    <DataLoadingWrapper
      isLoading={isLoading || isInitLoading || isFetching || isInitFetching}
      isError={isError}
      customErrorContentProps={{
        type: "custom",
        customTitle: "Form not found",
        customSubtitle: "This Form is missing or deleted",
        customImg: <Lottie options={{ animationData: linkExpiredAnimation }} />,
      }}
    >
      {isGuestApp ? (
        <div className="mx-auto h-full px-4 pt-[60px]">
          <div className="mx-auto flex min-h-full max-w-[720px] flex-col items-center rounded-t-xl bg-white p-6">
            {$content}
          </div>
        </div>
      ) : (
        $content
      )}
    </DataLoadingWrapper>
  );
};
