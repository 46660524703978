import { UserCPanelModel, UserRole } from "@jugl-web/rest-api";
import React, { useCallback } from "react";
import { Alert, Avatar } from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import { usersApi } from "@web-src/features/api/createApi";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useUserRoleName } from "@jugl-web/domain-resources/users/hooks/useUserRoleName";
import { ReactComponent as AlertIcon } from "./assets/alert.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";

export const UserRoleChangeAlert: React.FC<{
  isOpen: boolean;
  user: UserCPanelModel;
  role?: UserRole;
  onRequestClose: () => void;
}> = ({ isOpen, user, role, onRequestClose }) => {
  const { t } = useTranslations();
  const { toast } = useToast();
  const { roleNameMap } = useUserRoleName();

  const { entityId } = useEntitySelectedProvider();
  const { updateUser$ } = useCPanelPageContext();

  const [updateUsersApi, { isLoading }] = usersApi.useUpdateUserMutation();

  const updateUserRole = useCallback(async () => {
    if (!user?.entity_rel_id || !role) return;
    const response = await updateUsersApi({
      entityId,
      entityRelId: user.entity_rel_id,
      data: {
        role,
      },
    });
    if ("data" in response) {
      onRequestClose();
      toast(
        t(
          {
            id: "cpanel-page.user-role-changed",
            defaultMessage: "{username} became an {role} of the Workspace",
          },
          {
            username: user.display_name,
            role: roleNameMap[role],
          }
        )
      );
      updateUser$.next({
        id: user.entity_rel_id,
        data: { role },
      });
    }
  }, [
    user,
    updateUsersApi,
    entityId,
    role,
    onRequestClose,
    updateUser$,
    roleNameMap,
    t,
    toast,
  ]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isCloseButtonVisible
      title={t(
        {
          id: "cpanel-page.user-role",
          defaultMessage: "{roleName} Role",
        },
        {
          roleName: roleNameMap[role as UserRole],
        }
      )}
      img={
        <div className="relative grid h-[150px] w-[150px]">
          <Avatar
            imageUrl={user.profile?.img || ""}
            username={user.display_name || ""}
            className="h-full w-full"
          />
          <div className="absolute bottom-0 right-0 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
            <AlertIcon />
          </div>
        </div>
      }
      content={t(
        {
          id: "cpanel-page.change-role-confirmation-message",
          defaultMessage:
            "Do you really want to remove <highlightedText>{actualRoleName}</highlightedText> role for <highlightedText>{username}</highlightedText> and assign <highlightedText>{newRoleName}</highlightedText> role instead? 🔐",
        },
        {
          highlightedText: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800 font-semibold">{text}</span>
          ),
          actualRoleName: roleNameMap[user.role],
          newRoleName: roleNameMap[role as UserRole],
          username: user.display_name,
        }
      )}
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          role: "close",
        },
        {
          text: t({
            id: "common.confirm",
            defaultMessage: "Confirm",
          }),
          isDisabled: isLoading,
          onClick: updateUserRole,
        },
      ]}
    />
  );
};
